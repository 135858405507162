<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">會議排程與紀錄</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span>
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <!-- <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button> -->
      </div>
    </div>
    <div class="nav-tabs flex items-center mt-10 space-x-2">
      <a
        data-toggle="tab"
        data-target="#schedule"
        href="javascript:;"
        class=" text-base py-2 px-4 bg-white rounded-t-md active"
      >
        會議排程
      </a>
      <a
        data-toggle="tab"
        data-target="#meetingLog"
        href="javascript:;"
        class=" text-base py-2 px-4 bg-white rounded-t-md "
      >
        會議記錄
      </a>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-10 tab-content">
      <!-- 會議排程tab-content -->
      <div id="schedule" class="tab-content__pane active">
        <div class=" flex items-center justify-end">
          <button
            type="button"
            class="scheduleModeBtn button py-1"
            @click="
              () => {
                scheduleMode = 'list';
              }
            "
            v-bind:class="{ active: scheduleMode == 'list' }"
          >
            列表
          </button>
          ｜
          <button
            type="button"
            class="scheduleModeBtn button py-1"
            @click="
              () => {
                scheduleMode = 'calendar';
              }
            "
            v-bind:class="{ active: scheduleMode == 'calendar' }"
          >
            日曆
          </button>
        </div>
        <!-- 日曆 -->
        <div
          id="meetingCalendar"
          v-if="scheduleMode == 'calendar'"
          class=" p-5"
        >
          <FullCalendar
            class=" max-w-screen-xxl max-h-screen mx-auto"
            :options="calendarOptions"
          />
        </div>
        <!-- 列表 -->
        <div
          v-if="scheduleMode == 'list'"
          class="overflow-x-auto scrollbar-hidden mt-2"
        >
          <grid ref="grid" v-bind="gridOptionsSchedule" :autoResize="true">
            <template #title="{ row }">
              <div class="text-left">
                <a
                  @click="showDetail(row.roomId)"
                  href="javascript:;"
                  class=" inline-flex items-center"
                >
                  {{ row.productName }}
                </a>
              </div>
            </template>
          </grid>
        </div>
        <!-- 調整會議 -->
        <div id="meetingEdit" v-if="scheduleMode == 'detail'" class=" p-5">
          <div
            class="intro-y flex flex-col sm:flex-row items-center mt-8 pb-3 border-b border-theme-5 mb-5"
          >
            <h2 class="text-lg font-medium mr-auto">
              {{ meetingModal.title }}
            </h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0 justify-end">
              <button
                type="button"
                @click="
                  () => {
                    scheduleMode = 'list';
                  }
                "
                class="button text-black bg-gray-500 shadow-md mr-2 inline-flex items-center"
              >
                <ArrowLeftIcon class="mr-2 w-4 h-4" />
                返回排程
              </button>
              <!-- <button
                type="button"
                @click="meetingModal.save"
                class="button text-white bg-theme-1 shadow-md mr-2 inline-flex items-center"
              >
                <EditIcon class="mr-2 w-4 h-4" />
                {{ meetingModal.btn }}
              </button> -->
            </div>
          </div>
          <div class="">
            <div class=" xl:grid grid-cols-2 gap-5">
              <div class="">
                <!-- <div class=" sm:flex mb-5">
                  <CfInputBox
                      label="會議名稱"
                      name="title"
                      type="text"
                      v-model="meetingModal.formData.Name"
                  ></CfInputBox>
                </div> -->

                <div class=" sm:flex mb-5">
                  <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                    展覽
                  </div>
                  <div class="w-full input">
                    {{ formData.exhibitionName }}
                  </div>
                  <!-- <CfInputBox
                    label="展覽"
                    name="exhibition"
                    type="text"
                    placeholder="展覽"
                    v-model="meetingModal.formData.exhibition"
                  ></CfInputBox> -->
                </div>
                <div class=" sm:flex mb-5">
                  <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                    洽談商品
                  </div>
                  <div class="w-full input">
                    {{ formData.productName }}
                  </div>
                  <!-- <CfInputBox
                    label="洽談商品"
                    name="prosuct"
                    type="text"
                    placeholder="洽談商品"
                    v-model="meetingModal.formData.product"
                  ></CfInputBox> -->
                </div>
                <div class=" sm:flex mb-5">
                  <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                    日期
                  </div>
                  <div class="w-full input">
                    {{ getFormatTime(formData.meetingTime, "yyyy-MM-dd") }}
                  </div>
                  <!-- <CfInputBox
                    label="日期"
                    name="date"
                    type="date"
                    v-model="meetingModal.formData.date"
                  ></CfInputBox> -->
                </div>
                <div class=" sm:flex mb-5">
                  <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                    時間
                  </div>
                  <div class="w-full input">
                    {{ getFormatTime(formData.meetingTime, "HH:mm") }}
                  </div>
                  <!-- <CfInputBox
                    label="時間"
                    name="time"
                    type="time"
                    v-model="meetingModal.formData.time"
                  ></CfInputBox> -->
                </div>
                <div class=" sm:flex mb-5">
                  <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                    與會人
                  </div>
                  <div class="w-full input">
                    {{ formData.accountName }}
                  </div>
                  <!-- <CfInputBox
                    label="與會人"
                    name="member"
                    type="text"
                    value="XX公司"
                    placeholder="XX公司"
                    v-model="meetingModal.formData.members"
                  ></CfInputBox> -->
                </div>

                <div class=" grid grid-cols-3 sm:grid-cols-4 gap-5 px-10 mb-6">
                  <div
                    v-for="(item, index) in meetingModal.formData.members"
                    :key="index"
                    class=" text-center"
                  >
                    <img
                      :src="
                        formData.avatarId && formData.avatarId.length > 0
                          ? `${photoUrl}/${formData.avatarId}`
                          : '/images/no_photo.png'
                      "
                      onerror="this.onerror=null;this.src='/images/no_photo.png'"
                    />
                    <!-- <img
                      alt="Midone Tailwind HTML Admin Template"
                      class="rounded-full mx-auto w-24"
                      :src="
                        require(`@/midone/assets/images/${$f()[0].photos[0]}`)
                      "
                    /> -->
                    <div class="">{{ item }}</div>
                  </div>
                  <!-- <div class=" text-center">
                      <img alt="Midone Tailwind HTML Admin Template" class="rounded-full mx-auto w-24" :src="
                        require(`@/midone/assets/images/${$f()[0].photos[0]}`)
                      " />
                      <div class="">Alice 經理</div>
                    </div>
                    <div class=" text-center">
                      <img alt="Midone Tailwind HTML Admin Template" class="rounded-full mx-auto w-24" :src="
                        require(`@/midone/assets/images/${$f()[0].photos[0]}`)
                      " />
                      <div class="">Cindy 助理</div>
                    </div> -->
                </div>
              </div>
              <div class="">
                <div class="sm:flex mb-5">
                  <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                    會議室
                  </div>
                  <div class="w-full input">
                    <a :href="formData.url" target="meeting">{{
                      currentRoomId
                    }}</a>
                  </div>
                  <!-- <CfInputBox
                    label="會議室"
                    name="room"
                    type="text"
                    value="X-123"
                    placeholder="會議室"
                    v-model="meetingModal.formData.room"
                  ></CfInputBox> -->
                </div>
                <div class="sm:flex mb-5">
                  <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                    狀態
                  </div>
                  <div class="w-full">
                    {{ formData.status }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 會議紀錄tab-content -->
      <div id="meetingLog" class="tab-content__pane">
        <!-- <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <div class="xl:flex sm:mr-auto">
            <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
              <input
                type="text"
                class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
                v-model.trim="grid.keyword"
                placeholder="請輸入關鍵字"
                @keyup.enter="grid.refresh"
              />
            </div>
            <div class="mt-2 xl:mt-0">
              <button
                type="button"
                class="button w-full sm:w-16 bg-theme-1 text-white"
                @click="grid.refresh"
              >
                搜尋
              </button>
            </div>
          </div>
          <div class="flex mt-2 sm:mt-0">
            <div class="dropdown w-1/2 sm:w-auto ml-2">
              <button
                class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
              >
                <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
                <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
              </button>
              <div class="dropdown-box">
                <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                  <a
                    href="javascript:;"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    @click="grid.onExport({ type: 'csv' })"
                  >
                    <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="overflow-x-auto scrollbar-hidden mt-2">
          <grid
            ref="grid"
            v-bind="gridOptions"
            @removeSelectedRows="hideBatchDropDown()"
            :autoResize="true"
          >
            <template #record="{ }">
              <div>
                <a href="javascript:;" class=" mr-2 inline-flex items-center">
                  <FileTextIcon class="w-6 h-6 text-theme-1" />
                </a>
                <a href="javascript:;" class=" mr-2 inline-flex items-center">
                  <FilmIcon class="w-6 h-6 text-theme-7" />
                </a>
                <a href="javascript:;" class=" mr-2 inline-flex items-center">
                  <MicIcon class="w-6 h-6 text-red-500" />
                </a>
              </div>
            </template>
            <template #modal="{ row, submit, reset }">
              <vxe-form
                ref="form"
                v-bind="formOptions"
                :data="row"
                @reset="reset"
                @submit="submit"
              >
                <template #form-permissions="{ data }">
                  <check-box-list
                    :columnCount="4"
                    :items="permissionItems"
                    v-model="data.PermissionValues"
                  ></check-box-list>
                </template>
              </vxe-form>
            </template>
            <template #modal-footer>
              <vxe-button
                type="submit"
                status="primary"
                content="確認"
                @click="
                  $refs.form.validate(errMap => {
                    if (errMap === undefined)
                      $refs.form.dispatchEvent('submit');
                  })
                "
              ></vxe-button>
              <vxe-button
                type="reset"
                content="重置"
                @click="$refs.form.dispatchEvent('reset')"
              ></vxe-button>
            </template>
          </grid>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>

  <!--會議資訊modal -->
  <div id="meeting-modal" class="modal flex justify-center items-center">
    <div
      class="modal__content max-h-screen overflow-y-scroll sm:overflow-y-visible relative w-screen p-6 max-w-screen-lg"
    >
      <a
        data-dismiss="modal"
        href="javascript:;"
        class="absolute right-0 top-0 mt-6 mr-6"
      >
        <xIcon class="w-8 h-8 text-gray-500" />
      </a>
      <div class="">
        <!-- <div class="font-bold text-lg py-3 border-b border-theme-1">會議名稱XX產品量購合作商議</div> -->
        <div class=" p-0">
          <div class=" bg-gray-300 py-3">
            <div class="mb-2 sm:flex ">
              <div
                class=" w-full mr-2 sm:w-24 sm:flex justify-end text-base font-medium"
              >
                <CalendarIcon class="flex" />
              </div>
              {{ meetingModal.formData.date }}
            </div>
            <div class="mb-2 sm:flex ">
              <div
                class=" w-full mr-2 sm:w-24 sm:flex justify-end text-base font-medium"
              >
                <ClockIcon class="flex" />
              </div>
              {{ meetingModal.formData.time }}
            </div>
            <div class=" flex ">
              <div
                class=" w-full mr-2 sm:w-24 sm:flex justify-end text-base font-medium"
              >
                <AlertCircleIcon class="sm:flex" />
              </div>
              {{ meetingModal.formData.status }}
              <!-- {{
                meetingModal.formData.isCanceled
                  ? "已取消"
                  : meetingModal.formData.isStarted
                  ? meetingModal.formData.isFinished
                    ? "已結束"
                    : "進行中"
                  : "已預約"
              }} -->
            </div>
          </div>
          <div class=" py-3 mb-3 border-b border-theme-1">
            <div class="mb-2 sm:flex ">
              <div
                class=" w-full mr-2 sm:w-24 sm:flex justify-end text-base font-medium"
              >
                展覽
              </div>
              {{ meetingModal.formData.exhibition }}
            </div>
            <div class="mb-2 sm:flex ">
              <div
                class=" w-full mr-2 sm:w-24 sm:flex justify-end text-base font-medium"
              >
                洽談產品
              </div>
              {{ meetingModal.formData.product }}
            </div>
            <!-- <div class="mb-2 sm:flex ">
              <div class=" w-full mr-2 sm:w-24 sm:flex justify-end text-base font-medium">
                會議室
              </div>
              X-123 已預約
            </div>
            <div class="mb-2 sm:flex ">
              <div class=" w-full mr-2 sm:w-24 sm:flex justify-end text-base font-medium">
                供應商
              </div>
              ＸＸ公司
            </div> -->
          </div>
          <div class=" py-3 ">
            <div class="mb-2">
              <div
                class="mb-2 w-full mr-2 sm:w-24 sm:flex justify-end text-base font-medium"
              >
                與會人
              </div>
              <div class=" grid grid-cols-3 gap-5 px-10">
                <div class=" text-center">
                  <img
                    :src="
                      meetingModal.formData.avatarId &&
                      meetingModal.formData.avatarId.length > 0
                        ? `${photoUrl}/${meetingModal.formData.avatarId}`
                        : '/images/no_photo.png'
                    "
                    onerror="this.onerror=null;this.src='/images/no_photo.png'"
                  />
                  <div class="">{{ meetingModal.formData.accountName }}</div>
                </div>
                <!-- <div class=" text-center">
                  <img alt="Midone Tailwind HTML Admin Template" class="rounded-full" :src="
                    require(`@/midone/assets/images/${$f()[0].photos[0]}`)
                  " />
                  <div class="">Alice 經理</div>
                </div>
                <div class=" text-center">
                  <img alt="Midone Tailwind HTML Admin Template" class="rounded-full" :src="
                    require(`@/midone/assets/images/${$f()[0].photos[0]}`)
                  " />
                  <div class="">Cindy 助理</div>
                </div> -->
              </div>
            </div>
          </div>
          <div class=" text-center">
            <a
              href="javascript:;"
              @click="meetingModal.toEdit()"
              class="button button--lg text-white bg-theme-1 shadow-md flex items-center justify-center"
            >
              <InfoIcon class="mr-1 flxe" />
              詳細資訊
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CloudFun, {
  computed,
  defineComponent,
  ref,
  reactive,
  onMounted
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import CheckBoxList from "@/cloudfun/components/CheckBoxList.vue";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import formatDate from "xe-utils/toDateString";

export default defineComponent({
  components: {
    Grid,
    CheckBoxList,
    FullCalendar,
    CfInputBox
  },
  data() {
    return {};
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const meeting = ref<any>({});
    const currentId = ref<any>({});
    const scheduleMode = ref("list");
    const formData = ref<any>({});
    const currentRoomId = ref<string>("");
    const photoUrl = `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`;
    const getMeeting = () => {
      return model
        ?.dispatch("meeting/query", { pageSize: 999, page: 1 })
        .then(response => {
          console.log(response);
          if (response) {
            return response.data;
          } else {
            // CloudFun.send("error", {
            //   subject: "讀取失敗",
            //   content: response.message
            // });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };
    var printColumns = [{ field: "Name" }, { field: "Description" }];

    // 既有事件
    const eventClick = async (info: any) => {
      console.log("id: " + info.event.id);
      currentRoomId.value = info.event.id;
      const data = await meetingModal.find(info.event.id);
      console.log("data: " + data);
    };

    // 空白處點選
    const handleDateClick = (e: any) => {
      meetingModal.scheduleMode = "detail";
      meetingModal.title = "新增會議";
      meetingModal.reset(e);
    };

    const meetingModal = reactive({
      title: "",
      btn: "確認",
      scheduleMode: "list",
      formData: {
        // Id: 0,
        // Name: '',
        // Date: null,
        // Time: null,
        // Exhibition: '',
        // Product: '',
        // Room: '',
        // Status: 0,
        // People: [],
        id: 0,
        exhibition: "",
        isCanceled: false,
        isFinished: false,
        isStarted: false,
        meetingTime: "",
        members: [],
        product: "",
        record: null,
        room: null,
        date: "",
        time: "",
        status: "",
        accountName: "",
        avatarId: ""
      },
      insert() {
        var event = {
          id: meetingModal.formData.id,
          title: meetingModal.formData.exhibition,
          date: meetingModal.formData.date,
          display: "list-item",
          detail: meetingModal.formData
        };
        calendarOptions.events.push(event);
        meetingModal.toCalendar();
      },
      find(id: number) {
        if (id && id != 0) {
          model
            ?.dispatch("meeting/find", id)
            .then((response: any) => {
              if (response) {
                console.log(response);
                currentId.value = id;
                meetingModal.formData = response;
                meetingModal.formData.date = formatDate(
                  new Date(response.meetingTime),
                  "yyyy-MM-dd"
                );
                meetingModal.formData.time = formatDate(
                  new Date(response.meetingTime),
                  "HH:mm"
                );
                meetingModal.formData.status = response.status;
                meetingModal.formData.exhibition = response.exhibitionName;
                meetingModal.formData.product = response.productName;
                meetingModal.formData.avatarId = response.avatarId;
                meetingModal.formData.accountName = response.accountName;
                cash("#meeting-modal").modal("show");
                return response;
              } else {
                CloudFun.send("error", {
                  subject: "讀取失敗",
                  content: response.message
                });
              }
            })
            .catch((error: any) => {
              CloudFun.send("error", {
                subject: "讀取失敗",
                content: error.message
              });
            });
        } else {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: "無法讀取會議資料"
          });
        }
      },
      save() {
        if (meetingModal.formData.id) {
          meetingModal.insert();
        } else {
          meetingModal.insert();
        }
      },
      toList() {},
      toCalendar() {},
      toEdit() {
        meetingModal.title = "會議詳細資訊";
        cash("#meeting-modal").modal("hide");
        showDetail(currentRoomId.value);
      },
      reset(item: any) {
        currentId.value = 0;
        meetingModal.formData = {
          id: currentId.value,
          exhibition: "",
          isCanceled: false,
          isFinished: false,
          isStarted: false,
          meetingTime: "",
          members: [],
          product: "",
          record: null,
          room: null,
          date: item.dateStr,
          time: "",
          status: "",
          accountName: "",
          avatarId: ""
          // Id: 6,
          // Name: '',
          // Date: item.dateStr,
          // Time: null,
          // Exhibition: '',
          // Product: '',
          // Room: '',
          // Status: 0,
          // People: [],
        };
      }
    });

    const calendarOptions = reactive<any>({
      dayMaxEvents: 3,
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: "dayGridMonth",
      titleFormat: {
        // will produce something like "Tuesday, September 18, 2018"
        month: "long",
        year: "numeric",
        day: "numeric",
        weekday: "narrow"
      },
      headerToolbar: {
        left: "",
        center: "title",
        right: "prev,next"
      },
      locale: "zh-tw",
      height: "800px",
      dateClick: handleDateClick,
      eventClick: eventClick,
      events: [
        // {
        //   id: 3,
        //   title: '會議名稱XX會議',
        //   date: '2022-07-09',
        //   display: 'list-item'
        // },
        // {
        //   id: 4,
        //   title: '會議名稱SS會議',
        //   date: '2022-06-20',
        //   display: 'list-item'
        // },
        // {
        //   id: 5,
        //   title: '會議名稱QQ會議',
        //   date: '2022-06-14',
        //   display: 'list-item'
        // }
      ]
    });

    const gridOptions: GridOptions = {
      id: "meetingLog",
      title: "會議記錄",
      multiselect: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        custom: false,
        refresh: false
      },
      printConfig: {
        sheetName: "會議記錄",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "會議記錄",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      sortConfig: {
        showIcon: false
      },
      columns: [
        {
          field: "meetingTime",
          title: "會議時間",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          formatter: ({ cellValue }) =>
            formatDate(new Date(cellValue), "yyyy/MM/dd HH:mm")
        },
        {
          field: "exhibitionName",
          title: "展覽",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false
        },
        {
          field: "productName",
          title: "洽談產品",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false
        },
        {
          field: "accountName",
          title: "與會人",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false
        },
        // {
        //   field: "record",
        //   title: "紀錄",
        //   align: "center",
        //   slots: { default: "record" }
        // },
        {
          field: "isCanceled",
          title: "已取消",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          fixed: "right",
          resizable: false,
          formatter: ({ cellValue }) => (cellValue ? "是" : "否")
        },
        {
          field: "isStarted",
          title: "已開始",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          fixed: "right",
          resizable: false,
          formatter: ({ cellValue }) => (cellValue ? "是" : "否")
        },
        {
          field: "isFinished",
          title: "已完成",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          fixed: "right",
          resizable: false,
          formatter: ({ cellValue }) => (cellValue ? "是" : "否")
        }
      ],
      promises: {
        query: model
          ? params =>
              model.dispatch("meeting/query", {
                pageSize: params.pageSize,
                page: params.page
              })
          : undefined,
        // query: () => {
        //   return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //       resolve({
        //         "data":[
        //           {
        //             id: 1,
        //             date: '2021/07/26 22:03:56',
        //             exhibit: "2022數位週",
        //             product: "S 111-11 產品名稱",
        //             member: "XX公司",
        //             state: "已預約",
        //           },
        //           {
        //             id: 2,
        //             date: '2021/07/26 22:03:56',
        //             exhibit: "2022數位週",
        //             product: "S 111-11 產品名稱",
        //             member: "XX公司",
        //             state: "已完成",
        //           },
        //           {
        //             id: 3,
        //             date: '2021/07/26 22:03:56',
        //             exhibit: "2022數位週",
        //             product: "S 111-11 產品名稱",
        //             member: "XX公司",
        //             state: "已完成",
        //           },
        //           {
        //             id: 4,
        //             date: '2021/07/26 22:03:56',
        //             exhibit: "2022數位週",
        //             product: "S 111-11 產品名稱",
        //             member: "XX公司",
        //             state: "取消",
        //           },
        //         ],
        //         "totalCount":1,
        //       });
        //     }, 100);
        //   });
        // },
        queryAll: model ? () => model.dispatch("meeting/query") : undefined,
        save: model
          ? params => model.dispatch("meeting/save", params)
          : undefined
      },
      modalConfig: { showFooter: true }
    };

    const gridOptionsSchedule: GridOptions = {
      id: "meetingLog",
      title: "會議排程",
      multiselect: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        custom: false,
        refresh: false
      },
      printConfig: {
        sheetName: "會議排程",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "會議排程",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "roomId",
          title: "會議室",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: 100
        },
        {
          field: "meetingTime",
          title: "會議時間",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          minWidth: 250,
          formatter: ({ cellValue }) =>
            formatDate(new Date(cellValue), "yyyy-MM-dd HH:mm")
        },
        {
          field: "productName",
          title: "內容",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          minWidth: 200,
          slots: { default: "title" }
        },
        {
          field: "accountName",
          title: "洽詢人",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: 150
        },
        {
          field: "createdTime",
          title: "預約時間",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          minWidth: 200,
          formatter: ({ cellValue }) =>
            formatDate(new Date(cellValue), "yyyy-MM-dd HH:mm")
        }
      ],
      promises: {
        query: model ? () => model.dispatch("meeting/query") : undefined,
        queryAll: model ? () => model.dispatch("meeting/query") : undefined,
        save: model
          ? params => model.dispatch("meeting/save", params)
          : undefined
      },
      modalConfig: { showFooter: true }
    };

    const getFormatTime = (value: string, format: string) =>
      formatDate(new Date(value), format);

    const formOptions: VxeFormProps = {
      titleAlign: "right",
      items: [
        {
          field: "date",
          title: "會議時間",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "exhibit",
          title: "展覽",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "product",
          title: "洽談產品",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "members",
          title: "對象",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "record",
          title: "紀錄",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", required: true }]
      }
    };

    const permissionItems = computed(() => {
      const items: any[] = [];
      for (const permission of Object.values(
        model?.enums.SystemPermission || {}
      )) {
        if (permission.GroupName != null) {
          let group = items.find(e => e.name === permission.GroupName);
          if (!group)
            items.push(
              (group = { name: permission.GroupName, items: [], order: 0 })
            );
          group.items.push({
            name: permission.Name,
            value: permission.Value,
            order: permission.Order
          });
          if (group.order < permission.Order) group.order = permission.Order;
        } else
          items.push({
            name: permission.Name,
            value: permission.Value,
            order: permission.Order
          });
      }
      return items.sort((a: any, b: any) => a.order - b.order);
    });

    onMounted(async () => {
      meeting.value = await getMeeting();
      if (meeting.value && meeting.value.length) dataToCalendar();
    });

    const dataToCalendar = () => {
      meeting.value.forEach((data: any) => {
        var event = {
          id: data.roomId,
          title: data.productName,
          date: formatDate(new Date(data.meetingTime), "yyyy-MM-dd"),
          display: "list-item"
        };
        calendarOptions.events.push(event);
      });
    };

    const showDetail = async (roomId: any) => {
      scheduleMode.value = "detail";
      currentRoomId.value = roomId;
      formData.value = await model?.dispatch("meeting/find", roomId);
    };

    return {
      grid,
      gridOptions,
      formOptions,
      permissionItems,
      calendarOptions,
      meetingModal,
      currentId,
      scheduleMode,
      gridOptionsSchedule,
      showDetail,
      formData,
      photoUrl,
      currentRoomId,
      getFormatTime
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    }
  }
});
</script>
