
import { computed, defineComponent, PropType, toRef } from "vue";
import { useField } from "vee-validate";
export default defineComponent({
  props: {
    type: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Number, Array] as PropType<any>,
      required: true
    },
    placeholder: {
      type: String,
      default: ""
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    showMessage: {
      type: Boolean,
      default: true
    },
    maxLength: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const name = toRef(props, "name");

    const value = computed<any>({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit("update:modelValue", value);
      }
    });

    const { errorMessage, handleBlur, handleChange, meta } = useField(
      name,
      undefined,
      { initialValue: props.modelValue }
    );

    return {
      errorMessage,
      handleBlur,
      handleChange,
      meta,
      value
    };
  }
});
