
import CloudFun, {
  computed,
  defineComponent,
  ref,
  reactive,
  onMounted
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import CheckBoxList from "@/cloudfun/components/CheckBoxList.vue";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import formatDate from "xe-utils/toDateString";

export default defineComponent({
  components: {
    Grid,
    CheckBoxList,
    FullCalendar,
    CfInputBox
  },
  data() {
    return {};
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const meeting = ref<any>({});
    const currentId = ref<any>({});
    const scheduleMode = ref("list");
    const formData = ref<any>({});
    const currentRoomId = ref<string>("");
    const photoUrl = `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`;
    const getMeeting = () => {
      return model
        ?.dispatch("meeting/query", { pageSize: 999, page: 1 })
        .then(response => {
          console.log(response);
          if (response) {
            return response.data;
          } else {
            // CloudFun.send("error", {
            //   subject: "讀取失敗",
            //   content: response.message
            // });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };
    var printColumns = [{ field: "Name" }, { field: "Description" }];

    // 既有事件
    const eventClick = async (info: any) => {
      console.log("id: " + info.event.id);
      currentRoomId.value = info.event.id;
      const data = await meetingModal.find(info.event.id);
      console.log("data: " + data);
    };

    // 空白處點選
    const handleDateClick = (e: any) => {
      meetingModal.scheduleMode = "detail";
      meetingModal.title = "新增會議";
      meetingModal.reset(e);
    };

    const meetingModal = reactive({
      title: "",
      btn: "確認",
      scheduleMode: "list",
      formData: {
        // Id: 0,
        // Name: '',
        // Date: null,
        // Time: null,
        // Exhibition: '',
        // Product: '',
        // Room: '',
        // Status: 0,
        // People: [],
        id: 0,
        exhibition: "",
        isCanceled: false,
        isFinished: false,
        isStarted: false,
        meetingTime: "",
        members: [],
        product: "",
        record: null,
        room: null,
        date: "",
        time: "",
        status: "",
        accountName: "",
        avatarId: ""
      },
      insert() {
        var event = {
          id: meetingModal.formData.id,
          title: meetingModal.formData.exhibition,
          date: meetingModal.formData.date,
          display: "list-item",
          detail: meetingModal.formData
        };
        calendarOptions.events.push(event);
        meetingModal.toCalendar();
      },
      find(id: number) {
        if (id && id != 0) {
          model
            ?.dispatch("meeting/find", id)
            .then((response: any) => {
              if (response) {
                console.log(response);
                currentId.value = id;
                meetingModal.formData = response;
                meetingModal.formData.date = formatDate(
                  new Date(response.meetingTime),
                  "yyyy-MM-dd"
                );
                meetingModal.formData.time = formatDate(
                  new Date(response.meetingTime),
                  "HH:mm"
                );
                meetingModal.formData.status = response.status;
                meetingModal.formData.exhibition = response.exhibitionName;
                meetingModal.formData.product = response.productName;
                meetingModal.formData.avatarId = response.avatarId;
                meetingModal.formData.accountName = response.accountName;
                cash("#meeting-modal").modal("show");
                return response;
              } else {
                CloudFun.send("error", {
                  subject: "讀取失敗",
                  content: response.message
                });
              }
            })
            .catch((error: any) => {
              CloudFun.send("error", {
                subject: "讀取失敗",
                content: error.message
              });
            });
        } else {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: "無法讀取會議資料"
          });
        }
      },
      save() {
        if (meetingModal.formData.id) {
          meetingModal.insert();
        } else {
          meetingModal.insert();
        }
      },
      toList() {},
      toCalendar() {},
      toEdit() {
        meetingModal.title = "會議詳細資訊";
        cash("#meeting-modal").modal("hide");
        showDetail(currentRoomId.value);
      },
      reset(item: any) {
        currentId.value = 0;
        meetingModal.formData = {
          id: currentId.value,
          exhibition: "",
          isCanceled: false,
          isFinished: false,
          isStarted: false,
          meetingTime: "",
          members: [],
          product: "",
          record: null,
          room: null,
          date: item.dateStr,
          time: "",
          status: "",
          accountName: "",
          avatarId: ""
          // Id: 6,
          // Name: '',
          // Date: item.dateStr,
          // Time: null,
          // Exhibition: '',
          // Product: '',
          // Room: '',
          // Status: 0,
          // People: [],
        };
      }
    });

    const calendarOptions = reactive<any>({
      dayMaxEvents: 3,
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: "dayGridMonth",
      titleFormat: {
        // will produce something like "Tuesday, September 18, 2018"
        month: "long",
        year: "numeric",
        day: "numeric",
        weekday: "narrow"
      },
      headerToolbar: {
        left: "",
        center: "title",
        right: "prev,next"
      },
      locale: "zh-tw",
      height: "800px",
      dateClick: handleDateClick,
      eventClick: eventClick,
      events: [
        // {
        //   id: 3,
        //   title: '會議名稱XX會議',
        //   date: '2022-07-09',
        //   display: 'list-item'
        // },
        // {
        //   id: 4,
        //   title: '會議名稱SS會議',
        //   date: '2022-06-20',
        //   display: 'list-item'
        // },
        // {
        //   id: 5,
        //   title: '會議名稱QQ會議',
        //   date: '2022-06-14',
        //   display: 'list-item'
        // }
      ]
    });

    const gridOptions: GridOptions = {
      id: "meetingLog",
      title: "會議記錄",
      multiselect: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        custom: false,
        refresh: false
      },
      printConfig: {
        sheetName: "會議記錄",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "會議記錄",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      sortConfig: {
        showIcon: false
      },
      columns: [
        {
          field: "meetingTime",
          title: "會議時間",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          formatter: ({ cellValue }) =>
            formatDate(new Date(cellValue), "yyyy/MM/dd HH:mm")
        },
        {
          field: "exhibitionName",
          title: "展覽",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false
        },
        {
          field: "productName",
          title: "洽談產品",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false
        },
        {
          field: "accountName",
          title: "與會人",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false
        },
        // {
        //   field: "record",
        //   title: "紀錄",
        //   align: "center",
        //   slots: { default: "record" }
        // },
        {
          field: "isCanceled",
          title: "已取消",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          fixed: "right",
          resizable: false,
          formatter: ({ cellValue }) => (cellValue ? "是" : "否")
        },
        {
          field: "isStarted",
          title: "已開始",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          fixed: "right",
          resizable: false,
          formatter: ({ cellValue }) => (cellValue ? "是" : "否")
        },
        {
          field: "isFinished",
          title: "已完成",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          fixed: "right",
          resizable: false,
          formatter: ({ cellValue }) => (cellValue ? "是" : "否")
        }
      ],
      promises: {
        query: model
          ? params =>
              model.dispatch("meeting/query", {
                pageSize: params.pageSize,
                page: params.page
              })
          : undefined,
        // query: () => {
        //   return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //       resolve({
        //         "data":[
        //           {
        //             id: 1,
        //             date: '2021/07/26 22:03:56',
        //             exhibit: "2022數位週",
        //             product: "S 111-11 產品名稱",
        //             member: "XX公司",
        //             state: "已預約",
        //           },
        //           {
        //             id: 2,
        //             date: '2021/07/26 22:03:56',
        //             exhibit: "2022數位週",
        //             product: "S 111-11 產品名稱",
        //             member: "XX公司",
        //             state: "已完成",
        //           },
        //           {
        //             id: 3,
        //             date: '2021/07/26 22:03:56',
        //             exhibit: "2022數位週",
        //             product: "S 111-11 產品名稱",
        //             member: "XX公司",
        //             state: "已完成",
        //           },
        //           {
        //             id: 4,
        //             date: '2021/07/26 22:03:56',
        //             exhibit: "2022數位週",
        //             product: "S 111-11 產品名稱",
        //             member: "XX公司",
        //             state: "取消",
        //           },
        //         ],
        //         "totalCount":1,
        //       });
        //     }, 100);
        //   });
        // },
        queryAll: model ? () => model.dispatch("meeting/query") : undefined,
        save: model
          ? params => model.dispatch("meeting/save", params)
          : undefined
      },
      modalConfig: { showFooter: true }
    };

    const gridOptionsSchedule: GridOptions = {
      id: "meetingLog",
      title: "會議排程",
      multiselect: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        custom: false,
        refresh: false
      },
      printConfig: {
        sheetName: "會議排程",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "會議排程",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "roomId",
          title: "會議室",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: 100
        },
        {
          field: "meetingTime",
          title: "會議時間",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          minWidth: 250,
          formatter: ({ cellValue }) =>
            formatDate(new Date(cellValue), "yyyy-MM-dd HH:mm")
        },
        {
          field: "productName",
          title: "內容",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          minWidth: 200,
          slots: { default: "title" }
        },
        {
          field: "accountName",
          title: "洽詢人",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: 150
        },
        {
          field: "createdTime",
          title: "預約時間",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          minWidth: 200,
          formatter: ({ cellValue }) =>
            formatDate(new Date(cellValue), "yyyy-MM-dd HH:mm")
        }
      ],
      promises: {
        query: model ? () => model.dispatch("meeting/query") : undefined,
        queryAll: model ? () => model.dispatch("meeting/query") : undefined,
        save: model
          ? params => model.dispatch("meeting/save", params)
          : undefined
      },
      modalConfig: { showFooter: true }
    };

    const getFormatTime = (value: string, format: string) =>
      formatDate(new Date(value), format);

    const formOptions: VxeFormProps = {
      titleAlign: "right",
      items: [
        {
          field: "date",
          title: "會議時間",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "exhibit",
          title: "展覽",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "product",
          title: "洽談產品",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "members",
          title: "對象",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "record",
          title: "紀錄",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", required: true }]
      }
    };

    const permissionItems = computed(() => {
      const items: any[] = [];
      for (const permission of Object.values(
        model?.enums.SystemPermission || {}
      )) {
        if (permission.GroupName != null) {
          let group = items.find(e => e.name === permission.GroupName);
          if (!group)
            items.push(
              (group = { name: permission.GroupName, items: [], order: 0 })
            );
          group.items.push({
            name: permission.Name,
            value: permission.Value,
            order: permission.Order
          });
          if (group.order < permission.Order) group.order = permission.Order;
        } else
          items.push({
            name: permission.Name,
            value: permission.Value,
            order: permission.Order
          });
      }
      return items.sort((a: any, b: any) => a.order - b.order);
    });

    onMounted(async () => {
      meeting.value = await getMeeting();
      if (meeting.value && meeting.value.length) dataToCalendar();
    });

    const dataToCalendar = () => {
      meeting.value.forEach((data: any) => {
        var event = {
          id: data.roomId,
          title: data.productName,
          date: formatDate(new Date(data.meetingTime), "yyyy-MM-dd"),
          display: "list-item"
        };
        calendarOptions.events.push(event);
      });
    };

    const showDetail = async (roomId: any) => {
      scheduleMode.value = "detail";
      currentRoomId.value = roomId;
      formData.value = await model?.dispatch("meeting/find", roomId);
    };

    return {
      grid,
      gridOptions,
      formOptions,
      permissionItems,
      calendarOptions,
      meetingModal,
      currentId,
      scheduleMode,
      gridOptionsSchedule,
      showDetail,
      formData,
      photoUrl,
      currentRoomId,
      getFormatTime
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    }
  }
});
